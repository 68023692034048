<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      hide-overlay
      width="800px"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-container class="pa-0">
        <v-card class="pb-10">
          <form-header
            :title="title"
            :form="form"
            :permissions="permissions"
            :view="view"
            @viewChange="viewChanged"
            @saveForm="saveForm"
            @disableRecord="change_status"
            @newForm="newForm"
            @close="close"
            @cancelForm="cancelForm"
            @edit="view = 'edit'"
            :show-enable-button="false"
            :show-disable-button="false"
            :show-new-button="false"
            :readonly="true"
          />
          <v-divider />
          <v-card-text>
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              label-width="120px"
              label-position="top"
            >
              <div class="row">
                <div class="col-lg-6 col-sm-12" v-if="view == 'view'">
                  <el-form-item label="Event" prop="events_id">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.event"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Visitor Type" prop="visitor_type">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.visitor_type"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-4 col-sm-12" v-if="view == 'view'">
                  <el-form-item label="Visitor Name" prop="name">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.name"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-4 col-sm-12" v-if="view == 'view'">
                  <el-form-item label="Designation" prop="designation">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.designation"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-4 col-sm-12" v-if="view == 'view'">
                  <el-form-item label="Company" prop="company">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.company"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Mobile No" prop="mobile_no">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.mobile_no"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <el-form-item label="Email" prop="email">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.email"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item label="Age" prop="age">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.age"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item label="Gender" prop="gender">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.gender"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-4 col-sm-12" v-if="view == 'view'">
                  <el-form-item label="Visited Time" prop="visited_time">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.visited_time"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-4 col-sm-12" v-if="view == 'view'">
                  <el-form-item label="Left Time" prop="left_time">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.left_time"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12" v-if="view == 'view'">
                  <el-form-item label="Notify Text" prop="notify_text">
                    <el-input
                      size="mini"
                      :disabled="true"
                      :value="colFormatYesNo('', '', form.notify_text)"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12" v-if="view == 'view'">
                  <el-form-item label="Notify Email" prop="notify_email">
                    <el-input
                      size="mini"
                      :disabled="true"
                      :value="colFormatYesNo('', '', form.notify_email)"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-12 col-sm-12" v-if="view == 'view'">
                  <el-form-item label="Message" prop="message">
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="4"
                      :disabled="true"
                      v-model="form.message"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Status" prop="status">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.status"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </v-card-text>
          <!--<v-divider />-->
        </v-card>
        <v-overlay :value="loading" :opacity="0.7">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-container>
    </v-dialog>
  </v-row>
</template>
<script>
import Vue from "vue";
import { Button, Form, FormItem, Input } from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Input);
import FormHeader from "@/components/custom/FormHeader.vue";
export default {
  watch: {
    show: function () {
      this.showForm = this.show;
      this.permissions = this.perm;
      if (this.showForm) {
        if (this.empty(this.data)) {
          this.newForm();
        } else {
          this.view = "view";
        }
      }
    },
    data: function () {
      if (!this.empty(this.data)) {
        this.getRecord();
      }
    },
    perm: function () {
      this.permissions = this.perm;
    },
  },
  props: {
    data: null,
    title: {
      type: String,
      default: "",
    },
    end_point: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    perm: null,
    popup: {
      type: Boolean,
      default: false,
    },
  },
  newd() {},
  components: {
    FormHeader,
  },
  data() {
    return {
      form: {},
      view: "view",
      rules: {
        name: [
          {
            required: true,
            message: "Please enter User Name",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please enter User Email",
            trigger: "blur",
          },
        ],
        clients_id: [
          { required: true, message: "Please select Client", trigger: "blur" },
        ],
        mobile: [
          {
            required: true,
            message: "Please enter Mobile No",
            trigger: "blur",
          },
        ],
      },
      permissions: [],
      loading: false,
      showForm: false,
    };
  },
  methods: {
    saveForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      this.loading = true;
      var api = "create";
      if (!this.empty(this.form.id)) api = "update";
      this.api(this.end_point + api, this.form)
        .then((response) => {
          if (response.status == true) {
            this.loading = false;
            this.form = response.data;
            this.view = "view";
            this.showInfo("Record Saved successfully");
            this.$emit("updated");
            if (this.popup) this.close();
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    change_status(enable) {
      var api = "enable";
      if (!enable) api = "disable";
      this.loading = true;
      this.api(this.end_point + api, this.form)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newForm() {
      this.form = {
        id: null,
        client: null,
        event: null,
        visitor_type: null,
        nationality: null,
        name: null,
        email: null,
        designation: null,
        company: null,
        address: null,
        age: null,
        gender: null,
        mobile_no: null,
        message: null,
        visited_time: null,
        left_time: null,
        notify_email: null,
        notify_text: null,
        clients_id: null,
        events_id: null,
        event_codes_id: null,
        event_dates_id: null,
        event_fares_id: null,
        visitor_types_id: null,
        field_1: null,
        field_2: null,
        field_3: null,
        field_4: null,
        field_5: null,
        status: "Active",
      };
      this.view = "new";
    },
    getRecord() {
      let f = {
        id: this.data.id,
      };
      this.loading = true;
      this.api(this.end_point + "record", f)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
          this.permissions = response.permissions;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("close");
    },
    cancelForm() {
      this.view = "view";
      this.close();
    },
    viewChanged(val) {
      this.view = val;
    },
  },
  name: "VisitorsForm",
};
</script>
