<template>
  <v-container>
    <v-card>
      <index-header
        :title="title"
        :permissions="permissions"
        :reports="reports"
        @newRecord="newRecord"
        @viewRecord="viewRecord"
        @sizeChanged="sizeChanged"
        @fitlerData="fitlerData"
        @getData="getData"
        :showSearch="false"
        :showList="true"
        :noNew="true"
        listPlaceholder="Select Events"
        listEditor="events/event-list"
        @listChanged="listChanged"
        :show-custom-button="true"
        custom-button-color="success"
        custom-button-text="EXPORT TO XLS"
        @customButton="exportToExcel"
      />
      <v-divider />
      <v-card-text>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="name" label="Visitor Name"></el-table-column>
          <el-table-column
            prop="event"
            label="Event"
            :show-overflow-tooltip="true"
            width="250"
          ></el-table-column>
          <el-table-column
            prop="mobile"
            label="Mobile"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="email"
            label="Email"
            :show-overflow-tooltip="true"
            width="200"
          ></el-table-column>
          <el-table-column prop="age" label="Age" width="100"></el-table-column>
          <el-table-column
            prop="gender"
            label="Gender"
            :show-overflow-tooltip="true"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="nationality"
            label="Nationality"
            :show-overflow-tooltip="true"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="visited_time"
            label="Visited Time"
            :show-overflow-tooltip="true"
            :formatter="colFormatDateTime"
            width="170"
          ></el-table-column>
          <el-table-column
            prop="left_time"
            label="Left Time"
            :show-overflow-tooltip="true"
            :formatter="colFormatDateTime"
            width="170"
          ></el-table-column>
          <el-table-column
            prop="notify_text"
            label="Notify Text"
            width="130"
            align="center"
          >
            <template slot-scope="scope">
              {{ colFormatYesNo("", "", !empty(scope.row.notify_text)) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="notify_email"
            label="Notify Email"
            width="130"
            align="center"
          >
            <template slot-scope="scope">
              {{ colFormatYesNo("", "", !empty(scope.row.notify_email)) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="Status"
            width="100"
          ></el-table-column>
          <el-table-column fixed="right" label="..." width="40">
            <template slot-scope="scope">
              <v-icon
                style="cursor: hand"
                color="success"
                class="mr-1"
                @click="viewRecord(scope.row)"
              >
                mdi-eye
              </v-icon>
            </template>
          </el-table-column>
        </el-table>
        <index-footer
          :currentPage="currentPage"
          :total="total"
          :perPage="perPage"
          @sizeChanged="sizeChanged"
          @pageChanged="pageChanged"
        />
      </v-card-text>
      <v-divider />
    </v-card>
    <form-view
      :show="showForm"
      :title="title"
      :perm="permissions"
      :data="selectedRow"
      :end_point="end_point"
      @updated="getData"
      @close="
        selectedRow = null;
        showForm = false;
      "
    />
    <v-overlay :value="loading" :opacity="0.7">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import Vue from "vue";
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  ColorPicker,
} from "element-ui";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Button);
Vue.use(Pagination);
Vue.use(ColorPicker);
import formView from "./form.vue";
import IndexHeader from "@/components/custom/IndexHeader.vue";
import IndexFooter from "@/components/custom/IndexFooter.vue";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
export default {
  created() {
    this.getData();
  },
  components: {
    formView,
    IndexHeader,
    IndexFooter,
  },
  data() {
    return {
      end_point: "visitors/visitors-list/",
      tableData: [],
      reports: null,
      search: "",
      title: "Visitors List",
      currentPage: 1,
      perPage: 10,
      total: 0,
      sort: "event_bookings.created_at|desc",
      permissions: [],
      loading: false,
      showForm: false,
      selectedRow: null,
      events_id: null,
    };
  },
  methods: {
    newRecord() {
      this.showForm = true;
    },
    viewRecord(row) {
      this.selectedRow = row;
      this.showForm = true;
    },
    sizeChanged(page) {
      this.perPage = page;
      this.getData();
    },
    pageChanged(page) {
      this.currentPage = page;
      this.getData();
    },
    fitlerData(search_value, c_page) {
      this.search = search_value;
      this.currentPage = c_page;
      this.getData();
    },
    listChanged(item) {
      if (!this.empty(item)) {
        this.events_id = item.id;
        this.title = "Visitors List for " + item.name;
      } else {
        this.events_id = null;
        this.title = "Visitors List";
      }
      this.getData();
    },
    getData() {
      let f = {
        filter: this.search,
        page: this.currentPage,
        per_page: this.perPage,
        events_id: this.events_id,
        sort: this.sort,
      };
      this.loading = true;
      this.api(this.end_point + "get", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.tableData = response.data.data;
            this.total = response.data.total;
            this.reports = response.data.reports;
            this.permissions = response.data.permissions;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    exportToExcel() {
      let f = {
        events_id: this.events_id,
      };
      this.loading = true;
      this.api(this.end_point + "get-for-excel", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.saveExcel(response.data);
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveExcel(export_data) {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      var ws = XLSX.utils.json_to_sheet(export_data);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      var rightNow = new Date();
      var fileName =
        "visitors_" + rightNow.toISOString().slice(0, 10).replace(/-/g, "");
      saveAs(data, fileName + fileExtension);
    },
  },
  computed: {},
  name: "VisitorsList",
};
</script>
